import { inject, Injectable, Injector } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import Rollbar from 'rollbar';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class Logger {
    private injector = inject(Injector);
    private readonly rollbar = new Rollbar({
        enabled: environment.environment !== 'local',
        accessToken: environment.rollbarToken,
        captureUncaught: true,
        captureUnhandledRejections: true,
        environment: environment.environment,
        code_version: environment.version,
        addErrorContext: true,
        inspectAnonymousErrors: true,
    });

    public error(message: string, err: any) {
        if (environment.environment !== 'production') {
            console.info(message, err);
        }

        this.rollbar.error(message, err);
    };

    public apiError(message: string, err: any) {
        this.rollbar.error('API Error | ' + message, err);
        console.error(message, err);
        this.toastr.error('Something went wrong. Please try again later.');
        console.debug('API Error | ', message, err);
    }

    public clientError(message: string, err: any) {
        this.rollbar.error('Client Error | ' + message, err);
        this.toastr.error('Something went wrong. Please try again later.');
        console.debug('Client Error | ', message, err);
    }

    private get toastr(): ToastrService {
        return this.injector.get(ToastrService);
    }

}
